import React from "react";
import Contact from "./RequestDemoComponent/RequestDemo";
import About from "./AboutComponent/About";
import Products from "./OurProductsComponent/Products";
import Client from "./ClientComponent/Client";
import Demo from "./DemoComponent/Demo";
import ChoosingReason from "./ChoosingReasonComponent/ChoosingReason";
import Help from "./HelpComponent/Help";

export default function Home() {
  return (
    <>
      <About />
      <Products />
      <Demo />
      <ChoosingReason />
      {/* <Contact /> */}
      <Client />
      {/* <Help /> */}
    </>
  );
}
