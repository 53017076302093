import React from "react";
import "./ReturnRefundPolicy.css";
const ReturnRefundPolicy = () => {
  return (
    <div className="return-refund">
      <h1>Refund and Return Policy</h1>
      <p>
        <strong>**Refund Policy for Dzylo 1 Platform**</strong>
      </p>
      <p>
        At Dzylo, we strive to provide our customers with the best experience
        possible with our Dzylo 1 platform. However, we understand that
        circumstances may arise where you may need to request a refund. Please
        read our refund policy carefully to understand your rights and
        obligations when using our services.
      </p>
      <p>
        <strong>**60-Day Money-Back Guarantee**</strong>
      </p>
      <p>
        We offer a 60-day money-back guarantee on our Dzylo1 platform’s ONE YEAR
        Growth subscription plan. This refund is only available on Growth Plan.
        If you purchase Pro Plan where we do custom development and host your
        company branded client app on your domain, Refund is not applicable due
        to additional development efforts. If you want to try out Software and
        are not sure if you should go with Pro Plan, we advise you to start with
        Growth Plan first and once you are comfortable with software you can get
        it upgraded to Pro Plan. For Growth Plan are not satisfied with our
        service for any reason, you may request a full refund within 60 days of
        your initial purchase date.
      </p>
      <p>**Eligibility for Refund**</p>
      <p>To be eligible for a refund, you must meet the following criteria:</p>
      <p>
        1. Your refund request must be submitted within 60 days of your initial
        purchase date.
      </p>
      <p>
        <strong>**How to Request a Refund**</strong>
      </p>
      <p>
        To request a refund, please contact our customer support team at
        support@dzylo.com with your refund request. Or you can go on support
        page and drop msg to our Whatsapp Number +917303889822 Please include
        your full name, Company Name and Email address associated with your
        Dzylo account, and a detailed explanation of why you are requesting a
        refund.
      </p>
      <p>
        <strong>**Refund Processing**</strong>
      </p>
      <p>
        Once we receive your refund request, we will review it and notify you of
        the approval or rejection of your refund. If your refund is approved, we
        will process the refund to the original method of payment within 5-7
        business days.
      </p>
      <p>
        <strong>**Exceptions**</strong>
      </p>
      <p>
        Please note that certain circumstances may prevent us from issuing a
        refund, including:
      </p>
      <p>
        1. Violation of our terms of service or acceptable use policy.
        <br /> 2. Abuse of our refund policy.
        <br /> 3. Refund requests made after the 60-day period has expired.
      </p>
      <p>
        <strong>**Contact Us**</strong>
      </p>
      <p>
        If you have any questions about our refund policy or need assistance
        with your refund request, please contact our customer support team at
        contact@dzylo.com/support@dzylo.com.
      </p>
      <p>—</p>
      <p>
        This refund policy aims to provide clarity and transparency to customers
        regarding their rights and the process for requesting a refund for the
        Dzylo 1 platform subscription plans.
      </p>
    </div>
  );
};

export default ReturnRefundPolicy;
