"use client";
import React from "react";
import policyimg from "../../images/policy-image.png";
import "./PrivacyPolicy.css";

export default function PrivacyPolicy() {
	return (
		<div>
			<div className='privacy__policy'>
				<h1>Privacy Policy</h1>
				<p>At Virtuate Technologies Pvt Ltd. (“Virtuate”, “We” and “Us”) we care about your privacy and wants you to be familiar with how we collect, use, and disclose information. Virtuate adheres to ethical standards in gathering, using, and safeguarding any information you provide. This Privacy Policy describes our practices in connection with information that we or our service providers collect through the website or application (hereinafter the “Service”) operated and controlled by us from which you are accessing this Privacy Policy. By providing personal information to us or by using the Service, you acknowledge that you have read and understand this Privacy Policy.

By Personal Information, we mean any information that can either itself identify you as an individual (“Personally Identifying Information“) or that can be connected to you indirectly by linking it to Personally Identifying Information. Please note that usage of the term Personal Information in this Privacy Policy includes sensitive personal data or information, wherever appropriate and/or mandated under applicable laws.</p>
				<h4>Acceptance Of Privacy Policy</h4>
				<p>
				By providing your consent to this Privacy Policy and accepting our Terms of Use, you agree to the collection, use and transfer of your Personal Information as set out in this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use/access this Website further.

We will review this Privacy Policy from time to time to make sure it is up to date. If you are just a visitor, then please note that this Privacy Policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this Policy periodically. If you are our registered user, in Agreement with US, we will notify you before we make changes to this Policy and give you the opportunity to review the revised Policy before you choose to continue using our services.
				</p>
				<h4>Personal Information We Collect</h4>
				<p>
					Information You Provide: When you visit the Site, you provide with consent information, including information about your profile details, Name, Phone numbers, address, transactional information, Payment information. Additionally, as you browse the Site or engage in an Agreement with us, we collect information about the individual web pages or products that you view, or data that you share, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information”. However the Personal Information stored by us remains strictly Confidential and is not shared with any third party.

Information We Collect Automatically. We collect information while you access, browse, view or otherwise use the Site. In other words, when you access the Site we collect personal information on your usage of the Site, including transactions and communications with other users through the Site, your searches, the pages you visited, as well as other actions on Site. We Also, collect, use and process the information relating to such usage, including, IP address, device and connection information, browser information and web-log information. We also collect and process information relating to the use of cookies and similar technologies. We use that information to provide you our services, enhance user experience, personalize your browsing experience as well
as monitor the Site for preventing fraud and inappropriate content or behaviour.

Virtuate is committed to providing efficient customer service and outstanding value for all its products while respecting and protecting the privacy of its users. To serve them better, we may collect and maintain certain Personal Information, provided by the users, e.g. product and service preferences, billing address, payment information and other information. Under no circumstances do the company sells or rents this information to other businesses. The confidentiality of this information is strictly protected and enforced.
				</p>
				<h4>Our Legal Basis For Using Your Personal Information</h4>
				<p>
				Where relevant under applicable laws, all processing of your personal information will be justified by a “lawful ground” for processing. In the majority of cases, processing will be justified on the basis that:

 You have provided your consent for us to use your personal information for a specific purpose;
 Our use of your personal information is necessary to perform a contract or take steps to enter into a contract with you (e.g. to provide you with services which you have purchased);
 the processing is necessary to comply with a relevant legal obligation or regulatory obligation that we have (e.g. fraud prevention); or
 The processing is necessary to support our legitimate interests as a business (e.g. to improve our services to you), subject to your interests and fundamental rights and provided it is conducted at all times in a way that is proportionate. When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.
				</p>
				<h4>How do we use your personal Information?</h4>
				<p>
We use the Information that we collect generally as follows:
 Communicate with you;
 Screen our orders for potential risk or fraud; and When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.
We will use your personal information for the following purposes: to provide you with quality service and security, to operate the Site, to perform our obligations to you and to develop and improve our service. For example, we use personal information to verify your identity. We also use this information to establish and set up your account, verify or re-issue a password, log your activity, enable your communications with other members, provide customer support and contact you from time to time. The information helps us to develop and improve our services, to understand and analyse our performance as well as your preferences and performance and to customize and personalize our service and enhance your experience. to ensure marketplace integrity, prevent fraud and maintain a safe and secure marketplace and services. For example, we use personal information to monitor, track and prevent fraudulent activities and other inappropriate activities, monitor content integrity, conduct security investigations and risk assessments, verify or authenticate information provided by you, enforce our Terms of Service and comply with applicable laws. We conduct certain behavioural analytics to achieve the above objectives and in limited cases, if we detect activity that we think poses a risk to the Virtuate, other users, our community, or third parties, automated processes may restrict or limit your ability to use site.
 to contact you, as requested by you or as otherwise approved by you or permitted according to this Policy.
 to promote and advertise the Site, our services and the Virtuate marketplace. For example, we use the information collected from you for the purpose of sending direct marketing messages, to show you information that may be of interest to you, to organize and facilitate referral programs, contests or other promotional activities or events.
to maintain appropriate business records, to comply with lawful requests by public authorities and to comply with applicable laws and regulations or as otherwise required by law.

We will ask for your consent before using information for a purpose other than those set out in this Policy.
				</p>
				<h4>Direct marketing</h4>
				<p>
					● We use your personal information to send you direct marketing communications about our products, services or promotions from Virtuate that may be of interest to you or our related services. This may be via email, post, SMS, or targeted online advertisements.
● Our processing of your personal information for marketing purposes is based on our legitimate interest, or on your consent, or as otherwise permitted according to applicable laws. You have a right to prevent direct marketing of any form at any time – this can be exercised by following the opt-out link attached to each communication or by sending an email to contact@Virtuate.com.
● We take steps to limit direct marketing to a reasonable and proportionate level, and to send you communications which we believe may be of interest or relevance to you, based on the information we have about you.

Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
				</p>
				<h4>How long do we keep personal Information?</h4>
				<p>
					Virtuate uses your personal information to manage your account, to
					contact you and to operate, improve, and deliver our products and
					services, including Web sites. We use your personal information to
					provide you with information and services you request, to tell you
					about Virtuate products and services, and to give you a customized,
					interactive experience as you use our products. We use your personal
					information to charge you for the products and services you purchase.
					We use the contact information you provide to communicate with you. We
					use your information to prepare and share your report, and provide
					related assistance and services.
				</p>
				<h4>Log Data</h4>	
				<p>
We want to tell you that whenever you utilize our Service, just in case of a mistake within the app, we may collect data and knowledge (through third-party products) on your phone called Log Data. This Log Data contains all the information that is related to your device’s Internet Protocol (IP) address, device name, OS version, the configuration of the app, time and date of your usage, and other statistics.

No data shall be stored and shared with any third party by Virtuate unethically except for the provisions of this Privacy policy. Data shall be kept stored in reliable formats with Virtuate and shall be destroyed once the information is of no purpose..
				</p>
				<h4>Cookies</h4>
				<p>
				The Cookies are a special type of files that contain a small amount of information that is commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you simply have visited in the past. These cookies are stored in your device’s internal memory. This Service doesn’t use these “cookies” explicitly. However, the app may use third-party code and libraries that will use “cookies” to gather information and improve their services. You will get an option to either accept or refuse these cookies. In case, you choose to refuse our cookies, then in that case you’ll not be ready to use some portions of our Service.
				</p>
				<h4>Sharing Personal Information With Third Parties</h4>
				<p>
	We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent. We share your personal information with third parties as detailed below:

● For legal reasons. We share personal information with law enforcement agencies, public authorities or other parties in order to respond to a subpoena or court order, judicial process or to regulatory authorities, if we believe we are required to do so by law, or that doing so is reasonably necessary to comply with legal processes; when we believe it necessary or appropriate to disclose personal information to law enforcement authorities, such as to investigate actual or suspected fraud or violations of law, breaches of security, or breaches of this Policy; to respond to claims against us; and to protect the rights, property, or personal safety of Virtuate, our Partners, our customers, or the public;
● For payments and fraud detection. We share personal information with payment processors, fraud detection agencies and similar third parties for the purpose of facilitating payments done via the Site and securing the Site and protecting it against fraud, unauthorized transactions (such as money laundering), claims or other liabilities;
● With our corporate af iliates. We share personal information with Virtuate’s corporate affiliates that are related by common control for the purpose of operating the Site, providing our services to you and for other purposes listed herein; and
● In the context of a corporate transaction. Personal information will also be disclosed if we go through a business transition such as a merger, sale, transfer of all or a portion of Virtuate’s assets, acquisition, bankruptcy or similar event. In the event that we sell any business or assets, we will disclose your data to the prospective Customer. If we or substantially all of our assets are acquired by a third party, information held by us about our users will be one of the transferred assets.
				</p>

				<h4>Security</h4>
				<p>
We implement technical and organizational measures to maintain the security of the Site, our services and personal information and in preventing unauthorized access, loss, misuse, alteration, destruction or damage to it through industry standard technologies and internal procedures. In addition, we contractually ensure that any third party processing your personal information equally provides for confidentiality and integrity of your data in a secure way. However, the transmission of data via the internet is not completely secure, and although we will do our best to protect your personal information, we cannot guarantee the security of your data transmitted to the Site. Once we have received your data, we will use strict procedures and security features to try to prevent
unauthorized access. Users who have registered to the Site agree to keep their password in strict confidence and not disclose such password to any third party. Further information about our data security practices can be provided on request.
				</p>
		<h4> Changes </h4>
	<p>
	We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
	</p>
				<h4>Updating Personal Information</h4>
				<p>
We take steps to ensure that the personal information we collect is accurate and up to date, and we provide you with the opportunity to update your information through your account profile settings. In the event that you believe your information is in any way incorrect or inaccurate, please let us know immediately. We will make sure we investigate the matter and correct any inaccuracies as quickly as possible where necessary or give you ways to update it quickly or to delete it – unless we have to keep that information for legitimate business or legal purposes. When updating your personal information, we will ask you to verify your identity before we can act on your request. If for any reason you have a problem with deleting your personal information, please contact Virtuate’s Support and we will make reasonable efforts to delete any such information pursuant to any applicable privacy laws.

You can review and change your personal information by logging into the Site and visiting your account profile page.
				</p>
	<h4> Contact Us </h4>
	<p>
	To exercise any of your rights in connection with your personal information, we kindly ask that you contact us at contact@virtuate.ai.
Please include all the relevant details, so your requests can be handled correctly. We will process any requests in line with any local laws and our policies and procedures.
If you have any questions (or comments) concerning this Policy, please email our team at contact@dzylo.com, and we will make an effort to reply within a reasonable timeframe
	</p>
	
	
			</div>
		</div>
	);
}
