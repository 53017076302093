import React from "react";
import "./TermsAndConditions.css";
const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <p>Welcome to Dzylo ( Brand of Virtuate Technologies Pvt. Ltd. ).</p>
      <p>
        <strong>
          <em>
            Disclaimer: Terms and Conditions are legally binding undertaking
            between Dyzlo and website visitors which bind them to terms of use
            followed by Dyzlo for services.
          </em>
        </strong>
      </p>
      <p>
        The Pricing and Services shall be effective as on the date of acceptance
        of this Agreement.
      </p>
      <p>
        For updated Pricing and Services Please{" "}
        <a href="https://dzylo.com/pricing/">Click Here</a>
      </p>{" "}
      <p>
        Dyzlo (also <strong>“us”</strong>, <strong>“we”</strong>,{" "}
        <strong>“our”</strong>) provides this site as a service to its users. We
        request you to review the following basic rules that govern the use of
        the site. Please note that the use of the site constitutes the users’
        <strong>“Agreement”</strong> to follow and be bound by these terms. In
        case of disagreement over the terms, the usage of the site should be
        avoided.
      </p>
      <p>
        We are engaged in the business of providing software & technology
        solution to Interior & Construction businesses;
      </p>
      <p>
        <strong>
          Since we may revise this Agreement at any time, users should visit
          this page periodically to review the terms of their use.
        </strong>
      </p>
      <p>
        Please read the Terms of Service carefully before you start to use the
        Site.
      </p>
      <p>
        <strong>
          By using the Site, opening an account or by clicking to accept or
          agree to the Terms of Service when this option is made available to
          you, you accept and agree, on behalf of yourself or on behalf of your
          representative or any other entity (if applicable), to be bound and
          abide by these Terms of Service, Dyzlo Payment Terms (“Payment
          Terms”), and our Privacy Policy, found{" "}
          <a href="/privacy-policy">here</a>, each of which is incorporated
          herein by reference.
        </strong>
      </p>
      <p>
        If you do not want to agree to these Terms of Service or the Privacy
        Policy, you must not access or use the Site. For more detailed policies
        surrounding the activity and usage on the Site, please access the
        designated articles herein.
      </p>
      <p>
        This Site is offered and available to users who are 18 years of age or
        older. If you are under 18 you may not use this Site or the Dyzlo
        services without proper guidance and supervision. By using this Site,
        you represent and warrant that you are of legal age to form a binding
        contract and meet all of the foregoing eligibility requirements. If you
        do not meet all of these requirements, you must not access or use the
        Site.
      </p>
      <p>
        Our Support team is available if you have any questions regarding the
        Site or Terms of Service. Contacting our Support team can be performed
        by submitting a request to <a href="mailto: contact@dzylo.com">email</a>
        .
      </p>
      <p>
        The original language of these Terms of Service, as well as all other
        texts throughout the Site, is English. In case of conflicts between the
        original English version and any translation, the English version shall
        prevail.
      </p>
      <h3>Overview:</h3>
      <ul>
        <li>
          For using the Site you agree to provide us with accurate, complete and
          updated information and must not create an account for fraudulent or
          misleading purposes. You are solely responsible for any activity on
          your account and for maintaining the confidentiality and security of
          your password. We are not liable for any acts or omissions by you in
          connection with your account.
        </li>
        <li>For fees and payments please read the Payment Terms.</li>
        <li>
          Users may not offer or accept payments using any method other than
          placing an order through Site.
        </li>
        <li>
          We retain the right to use all delivered services for Dyzlo marketing
          and promotion purposes.
        </li>
        <li>
          We care about your privacy. You can read our Privacy Policy{" "}
          <a href="/privacy-policy">here</a>. The Privacy Policy is a part of
          these Terms of Service and incorporated herein by reference.
        </li>
        <li>
          We reserve the right to terminate engagement or restrict account of
          User if there is report of abuse, misbehaving or misbehavior of any
          sort.
        </li>
      </ul>
      <h3>Content of this Site</h3>
      <p>
        Occasionally, there may be information on the site that contains
        typographical errors, inaccuracies, or omissions that may relate to
        product descriptions, pricing, availability or quantity. We reserves the
        right to correct any errors, inaccuracies or omissions and to change or
        update information at any time without prior notice (including after the
        user has submitted his/her order). We apologize for any inconvenience
        this may cause. Unless otherwise noted, all materials, including images,
        illustrations, designs, icons, photographs and written materials that
        are part of this website (collectively, the <strong>“Contents”</strong>)
        are copyrights, trademarks and/or other intellectual property owned,
        controlled or licensed by registered brand Dyzlo . This site and all its
        Contents are intended solely for personal, non-commercial use. Users may
        download or copy the Contents and other downloadable materials displayed
        on the site for their personal use only. No right, title or interest in
        any downloaded materials or software is transferred to them as a result
        of any such downloading or copying. Users may not reproduce (except as
        noted above), publish, transmit, distribute, display, modify, create
        derivative works from, sell or participate in any sale of or exploit in
        any way, in whole or in part, any of the Contents, the site or any
        related software.
      </p>
      <p>
        <strong>
          Acknowledgements/representations And Warranties By Users
        </strong>
      </p>
      <p>
        Our website reserves the right to initiate civil and/or criminal
        proceedings against a user who, files an invalid and/or false claim or
        provides false, incomplete, or misleading information. In addition to
        the legal proceedings as aforesaid, we may at our sole discretion
        suspend, block, restrict, cancel the user id of such user and/or
        disqualify that user from using our website. Any person who knowingly
        and with an intention to injure, defraud or deceive, files a Fraudulent
        Complaint containing false, incomplete, or misleading information shall
        be guilty of a criminal offense and will be prosecuted to the fullest
        extent of the law.
      </p>
      <p>
        You acknowledge and undertake that you are accessing the services on the
        Website and transacting at third party websites at your own risk and are
        using your best and prudent judgment before making any purchases through
        those third party websites. We shall neither be liable nor be
        responsible for any actions or inactions of manufacturers of the
        products and hereby expressly disclaim any and all responsibility and
        liability in that regard.
      </p>
      <p>
        <strong>User Conduct and Protection</strong>
      </p>
      <p>
        We maintain a friendly, community spirited, and professional
        environment. Users should keep to that spirit while participating in any
        activity or extensions of Dyzlo. This section relates to the expected
        conduct users should adhere to while interacting with each other on our
        website.
      </p>
      <p>
        To report a violation of our Terms of Service, User Misconduct, or
        inquiries regarding your account, please contact our Support team by
        writing to <a href="mailto: contact@dzylo.com">Email</a>.
      </p>
      <h3>Our Statement</h3>
      <ul>
        <li>
          To protect our users’ privacy, user identities are kept anonymous,
          where possible. Requesting or providing Email addresses, Skype/IM
          usernames, telephone numbers or any other personal contact details to
          communicate outside of our website in order to circumvent or abuse the
          Dyzlo messaging system or our platform is not permitted.
        </li>
        <li>
          Any necessary exchange of personal information required to continue a
          service may be exchanged in the pages assigned within the application.
        </li>
        <li>
          We do not provide protection or recognise any agreements, whatsoever,
          in interactions outside of the Platform.
        </li>
        <li>
          All communication, information and file exchanges must be performed
          exclusively on platform.
        </li>
        <li>
          Rude, abusive, improper language or violent messages will not be
          tolerated and may result in an account warning or the
          suspension/removal of your account.
        </li>
        <li>
          Dyzlo is open to everyone. You undertake not to discriminate against
          any other user based on gender, race, age, religious affiliation,
          sexual orientation or otherwise and you acknowledge that such
          discrimination may result in the suspension/removal of your account.
        </li>
        <li>
          Users may not submit proposals or solicit parties introduced through
          Dyzlo to contract, engage with, or pay outside of the Platform.
        </li>
      </ul>
      <h3>Reporting Violations</h3>
      <p>
        If you come across any content that may violate our Terms of Service,
        you should report it to us through the appropriate channels created to
        handle those issues as outlined in our Terms of Service. All cases are
        reviewed by our relevant team. To protect individual privacy, the
        results of the investigation are not shared. You can review our Privacy
        Policy for more information.
      </p>
      <h3>Violations</h3>
      <p>
        Users may receive a warning to their account for violations of our Terms
        of Service or any user misconduct reported to our Trust and Safety team.
        A warning will be sent to the user’s email address and will be displayed
        for such user on the Site. Warnings do not limit account activity, but
        can lead to your account permanently disabled based on the severity of
        the violation.
      </p>
      <h3>Indemnification</h3>
      <p>
        User agrees to defend, indemnify and hold Dyzlo harmless from and
        against any and all claims, damages, costs and expenses, including
        attorneys’ fees, arising from or related to his/her use of the site.
      </p>
      <h3>Optional Tools</h3>
      <p>
        We may provide you with access to third-party tools over which nor we
        have monitoring rights nor controls nor inputs.
      </p>
      <p>
        You acknowledge and agree that we provide access to such tools{" "}
        <strong>“as is”</strong>
        and <strong>“as available”</strong> without any warranties,
        representations or conditions of any kind and without any endorsement.
        We shall have no liability whatsoever arising from or relating to your
        use of optional third-party tools.
      </p>
      <p>
        Any use by you of optional tools offered through the site is entirely at
        your own risk and discretion and you should ensure that you are familiar
        with and approve of the terms on which tools are provided by the
        relevant third-party provider(s).
      </p>
      <p>
        We may also, in the future, offer new services and/or features through
        the website (including, the release of new tools and resources). Such
        new features and/or services shall also be subject to these Terms of
        Service.
      </p>
      <h3>Personal Information</h3>
      <p>
        Your submission of Personal information through the store is governed by
        our Privacy Policy. To view our Privacy Policy please visit Link.
      </p>
      <h3>Prohibited Uses</h3>
      <p>
        In addition to other prohibitions as set forth in the Terms of Service,
        you are prohibited from using the site or its content: (a) for any
        unlawful purpose; (b) to solicit others to perform or participate in any
        unlawful acts; (c) to violate any international, federal, provincial or
        state regulations, rules, laws, or local ordinances; (d) to infringe
        upon or violate our intellectual property rights or the intellectual
        property rights of others; (e) to harass, abuse, insult, harm, defame,
        slander, disparage, intimidate, or discriminate based on gender, sexual
        orientation, religion, ethnicity, race, age, national origin, or
        disability; (f) to submit false or misleading information; (g) to upload
        or transmit viruses or any other type of malicious code that will or may
        be used in any way that will affect the functionality or operation of
        the Service or of any related website, other websites, or the Internet;
        (h) to collect or track the personal information of others; (i) to spam,
        phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or
        immoral purpose; or (k) to interfere with or circumvent the security
        features of the Service or any related website, other websites, or the
        Internet. We reserve the right to terminate your use of the Service or
        any related website for violating any of the prohibited uses.
      </p>
      <h3>Severability</h3>
      <p>
        In the event that any provision of these Terms of Service is determined
        to be unlawful, void or unenforceable, such provision shall nonetheless
        be enforceable to the fullest extent permitted by applicable law, and
        the unenforceable portion shall be deemed to be severed from these Terms
        of Service, such determination shall not affect the validity and
        enforceability of any other remaining provisions.
      </p>
      <h3>Termination</h3>
      <ul>
        <li>
          We may, at any time and without notice, suspend, cancel, or terminate
          your right to use the website (or any portion of the website). In the
          event of suspension, cancellation, or termination, you are no longer
          authorized to access the part of the website affected by such
          suspension, cancellation, or termination. In the event of any
          suspension, cancellation, or termination, the restrictions imposed on
          you with respect to material downloaded from the website and the
          disclaimers and limitations of liabilities set forth in the Agreement,
          shall survive.
        </li>
        <li>
          Without limiting the foregoing, we may close, suspend or limit your
          access to our website:
        </li>
      </ul>
      <p>
        – If we determine that you have breached, or are acting in breach of,
        this Agreement;
        <br />
        – If we determine that you have breached legal liabilities (actual or
        potential), including infringing someone else’s Intellectual Property
        Rights;
        <br />– If we determine that you have engaged, or are engaging, in
        fraudulent, or illegal activities;
        <br /> – To manage any risk of loss to us, a User, or any other person;
        or
        <br /> – For other similar reasons.
      </p>
      <h3>Assignment</h3>
      <ul>
        <li>
          You will not assign any rights or delegate any obligations under these
          Terms, in whole or in part, by operation of law or otherwise, without
          obtaining our prior written consent, which may be withheld in our sole
          discretion.
        </li>
        <li>
          We may assign our rights and delegate any of our obligations under
          these Terms, in whole or in part, without your consent. Any assignment
          or delegation in violation of the foregoing will be null and void.
          These Terms will be binding and inure to the benefit of each party’s
          permitted successors and assigns.
        </li>
      </ul>
      <h3>Waiver and Entire Agreement</h3>
      <p>
        The failure of us to exercise or enforce any right or provision of these
        Terms of Service shall not constitute a waiver of such right or
        provision.
      </p>
      <p>
        These Terms of Service and any policies or operating rules posted by us
        on this site or in respect to The Service constitutes the entire
        Agreement and understanding between you and us and govern your use of
        the Service, superseding any prior or contemporaneous agreements,
        communications and proposals, whether oral or written, between you and
        us (including, but not limited to, any prior versions of the Terms of
        Service).
      </p>
      <h3>Governing Law</h3>
      <p>
        These Terms of Service and any separate Agreements whereby we provide
        you Services shall be governed by and construed in accordance with the
        laws of Gurugram, Haryana,India.
      </p>
      <h3>Proprietary Restrictions</h3>
      <p>
        The Site, including its general layout, look and feel, design,
        information, content and other materials available thereon, is
        exclusively owned by Dyzlo and protected by copyright, trademark, and
        other intellectual property laws. Users have no right, and specifically
        agree not to do the following with respect to the Site or any part,
        component or extension of the Site (including its mobile applications):
        (i) copy, transfer, adapt, modify, distribute, transmit, display, create
        derivative works, publish or reproduce it, in any manner; (ii) reverse
        assemble, decompile, reverse engineer or otherwise attempt to derive its
        source code, underlying ideas, algorithms, structure or organization;
        (iii) remove any copyright notice, identification or any other
        proprietary notices; (iv) use automation software (bots), hacks,
        modifications (mods) or any other unauthorized third-party software
        designed to modify the Site; (v) attempt to gain unauthorized access to,
        interfere with, damage or disrupt the Site or the computer systems or
        networks connected to the Site; (vi) circumvent, remove, alter,
        deactivate, degrade or thwart any technological measure or content
        protections of the Site; (vii) use any robot, spider, crawlers or other
        automatic device, process, software or queries that intercepts, “mines,”
        scrapes or otherwise accesses the Site to monitor, extract, copy or
        collect information or data from or through the Site, or engage in any
        manual process to do the same, (viii) introduce any viruses, trojan
        horses, worms, logic bombs or other materials that are malicious or
        technologically harmful into our systems, (ix) use the Site in any
        manner that could damage, disable, overburden or impair the Site, or
        interfere with any other users’ enjoyment of the Site or (x) access or
        use the Site in any way not expressly permitted by these Terms of
        Service. Users also agree not to permit or authorize anyone else to do
        any of the foregoing.
      </p>
      <p>
        Except for the limited right to use the Site according to these Terms of
        Service, We own all right, title and interest in and to the Site
        (including any and all intellectual property rights therein) and you
        agree not to take any action(s) inconsistent with such ownership
        interests. We reserve all rights in connection with the Site and its
        content including, without limitation, the exclusive right to create
        derivative works.
      </p>
      <h3>DISPUTE RESOLUTIONS:</h3>
      <p>
        a) Any matter that does not cause turbulence in the relationship of
        Parties shall be decided through a meeting of Representatives within 15
        days from rising of dispute, other Material Disputes shall be resolved
        through Arbitration.
      </p>
      <p>
        b) Any disputes arising out of or in connection with this Agreement or
        its Interpretation which Parties agree to may be submitted by either
        Party to arbitration in Gurugram, Haryana in accordance with the
        Arbitration and Conciliation Act or any re-enactment or modification
        thereof. The arbitration proceedings shall be conducted in and any
        arbitration award shall be made in English. The costs and expenses of
        the arbitration tribunal shall be split evenly among the Parties.
      </p>
      <p>
        i. The Parties shall mutually agree on the arbitrator to preside over
        the Arbitration.
        <br />
        ii. Any decision or award of the arbitration tribunal shall be final and
        binding upon the parties unless otherwise decided by the arbitration
        tribunal.
      </p>
      <p>
        c) The Parties agree to conduct the arbitration proceedings virtually
        through such audio-visual means as may be mutually agreed upon by the
        Parties.
      </p>
      <p>
        i. The Parties agree that the arbitration award may, subject to any
        applicable law, be enforced against the party to the arbitration
        proceedings or their assets wherever they are located or may be found
        and that a judgment upon the arbitration award may be entered in any
        court having jurisdiction thereof.
        <br />
        ii. During the arbitration, the Parties hereto shall continue to
        exercise their other respective rights and fulfil their other respective
        obligations under this Agreement, except for the matters in dispute.
      </p>
      <h3>Confidentiality</h3>
      <p>
        We can recognize that there might be a need for Customers to disclose
        certain confidential information to be used by Partners for the purpose
        of delivering the ordered service, and to protect such confidential
        information from unauthorized use and disclosure. Therefore, Partners
        agree to treat any information received from Customers as highly
        sensitive, top secret and classified material. Without derogating from
        the generality of the above, Partners specifically agree to (i) maintain
        all such information in strict confidence; (ii) not disclose the
        information to any third parties; (iii) not use the information for any
        purpose except for delivering the ordered work; and (vi) not to copy or
        reproduce any of the information without the Customer’s permission.
      </p>
      <h3>General Terms</h3>
      <ul>
        <li>
          We reserve the right to put any account on hold or permanently disable
          accounts due to breach of these Terms of Service or due to any illegal
          or inappropriate use of the Site or services.
        </li>
        <li>
          Violation of Dyzlo’s Terms of Service may get your account disabled
          permanently.
        </li>
        <li>
          Users with disabled accounts will not be able to transact on our
          Platform.
        </li>
        <li>
          Users who have violated our Terms of Service and had their account
          disabled may contact our Support team for more information surrounding
          the violation and status of the account.
        </li>
        <li>
          Users have the option to enable account Security features to protect
          their account from any unauthorized usage.
        </li>
        <li>
          Users must be able to verify their account ownership through Support
          by providing materials that prove ownership of that account.
        </li>
        <li>
          Disputes should be handled using Dyzlo’s dispute resolution procedures
          or by contacting on our Support at{" "}
          <a href="mailto: contact@dzylo.com">Email</a>.
        </li>
        <li>
          We may make changes to our Terms of Service from time to time. When
          these changes are made, we will make a new copy of the terms of
          service available on this page.
        </li>
        <li>
          You understand and agree that if you use Dyzlo after the date on which
          the Terms of Service have changed, we will treat your use as
          acceptance of the updated Terms of Service.
        </li>
      </ul>
      <h3>PUBLICITY:</h3>
      <p>
        You agree and consents that Dyzlo may include Clients’ name and logo in
        its Client list on website for publicity purpose.
      </p>
      <h3>Disclaimer of Warranties</h3>
      <p>
        YOUR USE OF THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED
        THROUGH THE SITE IS AT YOUR OWN RISK. THE SITE, ITS CONTENT AND ANY
        SERVICES OR ITEMS OBTAINED THROUGH THE SITE ARE PROVIDED ON AN “AS IS”
        AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
        EXPRESS OR IMPLIED. NEITHER WE NOR ANY PERSON ASSOCIATED WITH DYZLO
        MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
        SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SITE.
      </p>
      <p>
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </p>
      <h3>Limitation on Liability</h3>
      <p>
        IN NO EVENT WILL DYZLO, ITS AFFILIATES OR THEIR LICENSORS, SERVICE
        PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR
        DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
        CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY WEBSITES
        LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER WEBSITES OR ANY
        SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR SUCH OTHER WEBSITES,
        INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
        PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN
        AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
        LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
        LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
        OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
      </p>
      <p>
        THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </p>
      <p>
        The term “Affiliate” referred to herein, is an entity that, directly or
        indirectly, controls, or is under the control of, or is under common
        control with Dyzlo.
      </p>
      <h3>Feedback Rights:</h3>
      <p>
        To the extent that you provide Dyzlo with any comments, suggestions or
        other feedback regarding the Dyzlo platform or the Site as a whole, as
        well as other of our products or services (collective, the{" "}
        <strong>“Feedback”</strong>), you will be deemed to have granted Dyzlo
        an exclusive, royalty-free, fully paid up, perpetual, irrevocable,
        worldwide ownership rights in the Feedback. We are under no obligation
        to implement any Feedback it may receive from users.
      </p>
      <h3>Digital Signature</h3>
      <ul>
        <li>
          By using our services, you are deemed to have executed this Agreement
          electronically; effective on the date you register your Account and
          start using our services. Your Account registration constitutes an
          acknowledgment that you are able to electronically receive, downloads,
          and print this Agreement.
        </li>
        <li>
          In connection with this Agreement, you may be entitled to receive
          certain records, such as contracts, notices, and communications, in
          writing. To facilitate your use of the website, you give us permission
          to provide these records to you electronically instead of in the paper
          form.
        </li>
        <li>
          By registering for an Account, you consent to electronically receive
          and access, via email, all records and notices for the services
          provided to you under this Agreement that we would otherwise be
          required to provide to you in paper form. However, we reserve the
          right, in our sole discretion, to communicate with you via the Postal
          Service and other third-party mail services using the address under
          which your account is registered. Your consent to receive records and
          notices electronically will remain in effect until you withdraw it.
          You may withdraw your consent to receive further records and notices
          electronically at any time by contacting Customer Support. If you
          withdraw your consent to receive such records and notices
          electronically, we will terminate your access to the Services, and you
          will no longer be able to use the Services. Any withdrawal of your
          consent to receive records and notices electronically will be
          effective only after we have a reasonable period of time to process
          your request for withdrawal. Please note that your withdrawal of
          consent to receive records and notices electronically will not apply
          to records and notices electronically provided by us to you before the
          withdrawal of your consent becomes effective.
        </li>
        <li>
          In order to ensure that we are able to provide records and notices to
          you electronically, you must notify us of any change in your email
          address by updating your Account information on the website or by
          contacting Customer Support.
        </li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;
