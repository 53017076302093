import React from "react";
import "../ChoosingReasonComponent/ChoosingReason.css";
const ChoosingReason = () => {
  return (
    <div className="choose-qna">
      <div className="question">Why Choose Us?</div>
      <div className="answer-container">
        <ul>
          <li>
            Expert Leadership Team from IIT and IIMs with combined Experience of
            50+ years of Tech and GenAI.
          </li>
          <li>
            We Delivered Three GenAI powered products for B2B Customers with
            500+ Paid Businesses in India, Indonesia, Oman, Australia.
          </li>
          <li>
            AI Solution Partner of two Enterprise SaaS companies deveoping GenAI
            products with in-house AI experts.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ChoosingReason;
