// import React from "react";
// import client from "../../../images/user.jfif";
// import "./Client.css";

// export default function Client() {
//   return (
//     <div className="client__component">
//       <h1>Our LeaderShip Team</h1>
//       <div className="details">
//         <img className="client__img" src={client} alt="client" />
//         <div className="client__detail">
//           <p className="name">
//             Anita Tailor <span>(Co-Founder & CTO)</span>
//             <span
//               style={{
//                 marginLeft: "10px",
//               }}
//             >
//               <a
//                 href="https://www.linkedin.com/in/anitatailor22"
//                 target="blank"
//               >
//                 <i className="fa fa-linkedin" />
//               </a>
//             </span>
//           </p>
//           {/* <p className="stars">
//             <i className="fa fa-star" />
//             <i className="fa fa-star" />
//             <i className="fa fa-star" />
//             <i className="fa fa-star" />
//             <i className="fa fa-star" />
//           </p> */}
//           {/* <p className="review">
//             We are using virtuate CRM solution along with our own self branded
//             client App and found both applications to be very useful and
//             effective. All the required workflows of interior design field has
//             been perfectly captured.
//           </p> */}
//         </div>
//       </div>
//     </div>
//   );
// }
import React from "react";
import "./Client.css";
import anita from "../../../images/Anita.jpeg";
import manoj from "../../../images/Manoj.jpeg";
import vivek from "../../../images/Vivek.jpeg";

const Client = () => {
  return (
    <>
      <div className="owner-component">
        <h1>Our Leadership Team</h1>
      </div>
      <div class="owners">
        <div class="owner-one">
          <a
            href="https://www.linkedin.com/in/manoj-jindal-89041014/"
            target="_blank"
          >
            <img src={manoj} alt="" />
          </a>
          <h3>Manoj Jindal</h3>
          <h4>(Founder & CEO)</h4>
        </div>
        <div class="owner-two">
          <a href="https://www.linkedin.com/in/anitatailor22" target="_blank">
            <img src={anita} alt="" />
          </a>
          <h3>Anita Tailor</h3>
          <h4>(Co-Founder & CTO)</h4>
        </div>
        <div class="owner-three">
          <a
            href="https://www.linkedin.com/in/vivek-rai-bb687483/"
            target="_blank"
          >
            <img src={vivek} alt="" />
          </a>
          <h3>Vivek Rai</h3>
          <h4>(Co-Founder & COO)</h4>
        </div>
      </div>
    </>
  );
};

export default Client;
