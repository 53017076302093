import React from "react";
import backgroundImage from "../../../images/background.png";
import daizyLogo from "../../../images/daizyLogo.jpeg";
import aiImage from "../../../images/ai - icon.png";
import star from "../../../images/vector-star.png";
import aiBackground from "../../../images/ai-background.svg";
import "./About.css";
import { HashLink as Link } from "react-router-hash-link";

export default function About() {
  return (
    <div className="about">
      <div className="jumbotron">
        <div className="jumbotron__content">
          <div className="text">
            <h4
              style={{
                fontWeight: "bold",
                fontSize: "45px",
                marginTop: "-70px",
              }}
            >
              Transforming Your Ideas into AI Solutions
            </h4>
          </div>
          {/* <p
            style={{
              margin: "10px auto 0px",
              fontSize: "26px",
              width: "60%",
            }}
          >
            Discover the Future of Interior Design: Explore the Dzylo1 Platform
            and Meet Daizy, Your AI Assistant.
          </p> */}
          {/* <div className="ai-prompt-body">
            <div class="box">
              <textarea
                placeholder="Enter prompt, for example: Create a contemporary style living room with warm colour tones with a 3 seats sofa, side units, centre table, tv units and some indoor plants"
                className="input-prompt"
                style={{
                  overflow: "hidden",
                }}
              ></textarea>
              <hr />
              <button className="generate-button">
                <span>
                  <img src={star} />
                </span>
                <span>Generate with AI</span>
              </button>
            </div>
          </div> */}
          <div className="ai-body">
            <div
              style={{
                backgroundColor: "#3E7C00",
                width: "95px",
                color: "white",
                borderTopLeftRadius: "12px",
              }}
            >
              Spotlight
            </div>
            <div class="content">
              <div class="left">
                {/* <div class="image">
                  <img
                    src={aiImage}
                    style={{ height: "50px", width: "50px" }}
                  />
                </div> */}
                <div class="title">
                  <img src={daizyLogo} style={{ width: "8rem" }} />
                  <h4>Your AI Assistant</h4>
                </div>
              </div>
              <div class="middle">
                <div style={{ fontSize: "18px", color: "#393939" }}>
                  Ask your Interior related queries to Daizy
                </div>
                {/* <button
                  style={{
                    padding: "6px 7px",
                    fontSize: "20px",
                    marginTop: "15px",
                    backgroundColor: "#2a546f",
                    color: "white",
                    border: "none",
                    borderRadius: "6px",
                  }}
                >
                  <a
                    href="https://dzylo.com"
                    style={{ color: "white", textDecoration: "none" }}
                    target="_blank"
                  >
                    Try Now &gt;
                  </a>
                </button> */}
                <div class="image">
                  <img
                    src={aiImage}
                    style={{ height: "50px", width: "50px" }}
                  />
                </div>
              </div>
              <div className="right">
                <img
                  src={aiBackground}
                  style={{ width: "168px", height: "100px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
