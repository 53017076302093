import React from "react";
import "./Products.css";
import firstImage from "../../../images/FirstProductImage.gif";
import thirdImage from "../../../images/ThirdProductImage.gif";
import "./VirtuateProducts.css";

export default function Products() {
  return (
    <div className="products">
      <h1
        style={{
          fontSize: "50px",
        }}
      >
        Our flagship Products and Services
      </h1>
      <div className="product-content">
        <div className="first">
          <div className="media">
            <img src={thirdImage} />
          </div>
          <div className="data">
            <h3
              style={{
                color: "#013354",
                marginBottom: "30px",
                fontSize: "30px",
              }}
            >
              Partner with us for Developing your AI Powered Solutions
            </h3>
            <ul className="check-list">
              <li>Build on Azure AI Studio</li>
              <li>AWS Bedrock and Amazon Q</li>
              <li>FineTune your LLMs</li>
              <li>Custom AI Apps Development</li>
            </ul>
            <button
              style={{
                backgroundImage:
                  "linear-gradient(-180deg, #628294 0%, #013354 100%, #ffffff 100%)",
                color: "white",
                borderRadius: "8px",
                padding: "8px 10px",
                fontSize: "18px",
                fontWeight: "bold",
                color: "white",
                marginLeft: "3rem",
              }}
            >
              <a
                href="https://outlook.office365.com/owa/calendar/AI1@virtuate.ai/bookings/"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                Get Free Consultation
              </a>
            </button>
          </div>
        </div>
        <div className="second">
          <div className="media">
            <img src={firstImage} />
          </div>
          <div className="data">
            <h3
              style={{
                color: "#013354",
                marginBottom: "30px",
                fontSize: "30px",
              }}
            >
              All in One Business Management Software for Interior Businesses
            </h3>
            <ul className="check-list">
              <li>Project and Document Management</li>
              <li>Chat with Client</li>
              <li>Mobile App for Your Clients</li>
            </ul>
            <button
              style={{
                backgroundImage:
                  "linear-gradient(-180deg, #628294 0%, #013354 100%, #ffffff 100%)",
                color: "white",
                borderRadius: "8px",
                padding: "8px 10px",
                fontSize: "18px",
                fontWeight: "bold",
                color: "white",
                marginLeft: "3rem",
              }}
            >
              <a
                href="https://one.dzylo.com/#/signup"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                Start Now
              </a>
            </button>
          </div>
        </div>
        <div className="third">
          <div className="media">
            <img src="https://dzylo-crm-dev.s3.ap-south-1.amazonaws.com/Virtuate/SecondProductVideo.gif" />
          </div>
          <div className="data">
            <h3
              style={{
                color: "#013354",
                marginBottom: "30px",
                fontSize: "30px",
              }}
            >
              Create Virtual tours and give 3D walk through to your clients
            </h3>
            <ul className="check-list">
              <li>Use 3D Renders from Sketchup and Convert to Tour</li>
              <li>Create Mood Board with 2D Images for Presentations</li>
            </ul>
            <button
              style={{
                backgroundImage:
                  "linear-gradient(-180deg, #628294 0%, #013354 100%, #ffffff 100%)",
                color: "white",
                borderRadius: "8px",
                padding: "8px 10px",
                fontSize: "18px",
                fontWeight: "bold",
                color: "white",
                marginLeft: "3rem",
              }}
            >
              <a
                href="https://tour.dzylo.com/"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                Take A Tour
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
