import "./whatsapp.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareWhatsapp } from "@fortawesome/free-brands-svg-icons";

const WhatsAppIcon = () => (
  <a
    href="https://api.whatsapp.com/send/?phone='9873009536'&type=phone_number&app_absent=0"
    className="whatsapp-top"
    target="_blank"
  >
    <FontAwesomeIcon icon={faSquareWhatsapp} />
  </a>
);
export default WhatsAppIcon;
