import React from "react";
import "../DemoComponent/Demo.css";
import arrow from "../../../images/arrow.png";
const Demo = () => {
  return (
    <div className="demo">
      <h2>"Partner with us and Let's Co-Develop your AI Solution"</h2>
      <button
        style={{
          backgroundImage:
            "linear-gradient(-180deg, #628294 0%, #013354 100%, #ffffff 100%)",
          color: "white",
          borderRadius: "8px",
          padding: "8px 10px",
          fontSize: "18px",
          fontWeight: "bold",
          color: "white",
        }}
      >
        <span>
          <a
            href="https://outlook.office365.com/owa/calendar/AI1@virtuate.ai/bookings/"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            Book Meeting
          </a>
        </span>
      </button>
    </div>
  );
};

export default Demo;
